import {makeStyles} from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
  container: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '56px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '24px'
    }
  },
  pictureSingle: {
    width: '50%',
    height: 'auto',
    maxHeight: '120px'
  },

  pictureDouble: {
    height: '100%',
    width: '100%'
  }
}))
